$primaryColor: #f9004d;
$primaryColorHover: #f61b10;

$secondaryColor: #2494d9;
$secondaryColorHover: #2085c3;

$textColor: #c6c9d8;

//===== Colors =====//
$theme-color: #f9004d;
$theme-color-2: #f61b10;
$theme-color-3: #ef0963;
$body-color: #1d1d24;
$heading-color: #1f1f25;
$border-color: #d4d4d4;
$white: #fff;

$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;

.container {
  position: relative;
  min-width: 300px;
  max-width: 400px;
  padding: 30px 35px;
  border-radius: 10px;
  background: transparent;

  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);

  &::before {
    transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#f61b10), to(#ef0963));
    background-image: -webkit-linear-gradient(left, #f61b10, #ef0963);
    background-image: linear-gradient(90deg, #f61b10, #ef0963);
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
  }

  &:hover {
    box-shadow: 0 10px 25px 10px rgb(0 0 0 / 10%);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    color: white;

    &::before {
      visibility: visible;
      opacity: 100%;
    }
  }
}

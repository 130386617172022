@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.burgerButton {
  height: 30px;
  width: 30px;
  margin: 10px 0;
  color: white;

  @include media('>=lgTablet') {
    display: none;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.menuContainer {
  margin-left: 100px;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
  height: 100vh;

  position: fixed;
  background-color: white;
  z-index: 91;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-right: 25px;
  padding-left: 25px;

  transition: transform 0.4s ease-in-out;

  &.hide {
    transform: translateX(100%);
  }

  &.visible {
    transform: translateX(0);
  }

  .header {
    width: 100%;
    padding: 20px 0 0 20px;

    .closeContainer {
      margin-left: auto;
      width: 30px;
      height: 30px;
      color: black;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes backdropAnimation {
  0% {
    z-index: 90;
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@import '../../styles/variables.scss';

.button {
  min-width: 170px;
  position: relative;
  display: inline-block;

  -webkit-transition: 0.3s;
  transition: 0.3s;

  text-transform: uppercase;
  border-radius: 6px;

  &.fullWidth {
    width: 100%;
  }

  &.medium {
    padding: 15px 30px;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 500;
  }

  &.small {
    padding: 0 25px;
    height: 46px;
    line-height: 42px;
    font-size: 14px;
  }

  &.default {
    background: transparent;
    color: #f9004d;
    border: 2px solid #f9004d;
    &:hover {
      color: white;
      background-color: $primaryColor;
      box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
      // -webkit-transform: translateY(-5px);
      // transform: translateY(-5px);
    }
  }

  &.outline {
    color: #c6c9d8;
    background: transparent;
    border: 2px solid #b1b4c1;
    &:hover {
      border: 2px solid $primaryColorHover;
      background: $primaryColorHover;
      color: #fff;

      box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }

  &:disabled {
    color: #c6c9d8;
    background: transparent;
    border: 2px solid #b1b4c1;
    &:hover {
      border-color: #b1b4c1;
      color: #c6c9d8;
      background: transparent;
      cursor: default;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.4s;
}

.hide {
  opacity: 0;
}

.visible {
  opacity: 100%;
}

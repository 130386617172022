@import '../../styles/variables.scss';

.container {
  width: 100%;
  .input {
    padding: 0 20px;
    width: 100%;
    height: 50px;

    display: block;
    border: 2px solid rgba(225, 220, 220, 0.1);
    border-radius: 5px;
    outline: none;

    font-family: 'Poppins';
    font-size: 15px;
    line-height: 46px;
    letter-spacing: 0.1px;
    color: #fff;
    background-color: #191919;

    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      border-color: $primaryColor;
    }

    &.error {
      border-color: red;
    }

    &.textarea {
      resize: vertical;
      height: 125px;
      font-size: 16px;
      line-height: 32px;
    }
  }

  .errorMessage {
    margin-top: 6px;
    margin-left: 6px;
    display: block;
    font-size: 12px;
    color: red;
  }
}

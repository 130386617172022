@import '../../styles/variables.scss';

.clientCard {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  transition: all 0.3s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    background-color: $primaryColor;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s;
  }

  &.active {
    transform: scale(1.1);
    &::after {
      transform: scale(1);
    }
  }
}

@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  width: 100%;
  height: auto;
  padding: 20px 30px;

  background: #101010;
  // background: linear-gradient(180deg, rgba(28, 29, 35, 1) 0%, rgba(30, 31, 37, 1) 100%);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media('<smTablet') {
    flex-direction: column;
  }

  .logoContainer {
    height: 60px;
    img {
      height: 100%;
    }
  }

  .socialContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    .socialButton {
      margin: 0 6px;
      display: inline-block;
      width: 50px;
      height: 50px;
      font-size: 16px;

      border: 2px solid rgba(198, 201, 216, 0.75);
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s;

      svg {
        width: 50%;
        height: 50%;
      }

      &:hover {
        transform: translateY(-2px);
        border-color: $primaryColorHover;
      }
    }
  }

  .label {
    font-size: 14px;
    color: $textColor;
    opacity: 0.75;
  }
}

@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;

  @include media('<laptop') {
    flex-direction: column;
    padding-top: 30px;
    align-items: center;
  }

  .header {
    width: 35%;
    max-width: 400px;
    margin-top: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    h1 {
      margin-top: 8px;
      font-size: 50px;
      font-weight: 700;
    }
    p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 28px;
      color: $textColor;
    }
    @include media('<laptop') {
      width: 70%;
      text-align: center;
    }

    @include media('<smTablet') {
      margin-top: 0;
      h1 {
        font-size: 36px;
        font-weight: 700;
      }
      p {
        margin-top: 15px;
        font-size: 16px;
        line-height: 28px;
        color: $textColor;
      }
    }
  }

  .servicesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include media('<smTablet') {
      grid-template-columns: 1fr;
      gap: 5px;
    }

    .card {
      @include media('<smTablet') {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 12px;
      }

      .iconContainer {
        width: 54px;
        height: 54px;
        margin-bottom: 20px;
        color: $primaryColor;
        transition: color 0.4s;
        svg {
          width: 100%;
          height: 100%;
        }
      }

      h3 {
        font-size: 19px;
        font-weight: 500;
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        line-height: 28px;
        color: $textColor;
        @include media('<smTablet') {
          text-align: center;
        }
      }

      &:hover {
        box-shadow: 0 10px 25px 10px rgb(0 0 0 / 10%);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        color: white;

        &::before {
          visibility: visible;
          opacity: 100%;
        }
        .iconContainer {
          color: white;
        }
        p {
          color: white;
        }
      }
    }
  }
}

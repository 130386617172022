@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;

  .header {
    width: 60%;
    max-width: 400px;

    margin-bottom: 20px;
    text-align: center;
    h1 {
      margin-top: 8px;
      font-size: 50px;
      font-weight: 700;
    }
    p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 28px;
      color: $textColor;
    }
    @include media('<laptop') {
      width: 70%;
      text-align: center;
    }

    @include media('<smTablet') {
      margin-top: 0;
      h1 {
        font-size: 36px;
        font-weight: 700;
      }
      p {
        margin-top: 15px;
        font-size: 16px;
        line-height: 28px;
        color: $textColor;
      }
    }
  }

  .content {
    width: 100%;
  }
}

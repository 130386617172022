.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 92;

  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.4s, transform 0.4s;

  &.visible {
    opacity: 100%;
    transform: scale(1);
  }
}

@import '../../styles/variables';
@import '../../styles/breakpoints.scss';

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 90px;

  @include media('<desktop') {
    padding-left: 70px;
    padding-right: 70px;
  }
  @include media('<lgTablet') {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media('<mobile') {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@import '../../styles/breakpoints.scss';

nav {
  display: flex;
  margin-right: 40px;

  @include media('<=lgTablet') {
    display: none;
  }

  .navLink {
    margin-left: 30px;
  }
}

@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  padding-top: 30px;
  padding-bottom: 40px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #191919;

  .header {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: center;
    h1 {
      margin-top: 8px;
      font-size: 50px;
      font-weight: 700px;

      background-image: linear-gradient(145deg, #f81f01, #ee076e);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 28px;
      color: $textColor;
    }

    @include media('<smTablet') {
      h1 {
        font-size: 36px;
      }
    }
  }

  .techsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 400px));
    justify-items: center;
    justify-content: center;
    gap: 10px;

    @include media('<laptop') {
      grid-template-columns: repeat(2, minmax(auto, 400px));
    }

    @include media('<smTablet') {
      grid-template-columns: 1fr;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media('<smTablet') {
        padding: 15px;
      }

      .iconContainer {
        margin-bottom: 16px;
        padding: 12px;
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 50%;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      h3 {
        font-size: 24px;
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;

        color: $textColor;
      }

      &:hover {
        p {
          color: white;
        }
      }
    }
  }
}

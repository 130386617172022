@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  padding: 50px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  background-color: #191919;

  @include media('<smTablet') {
    padding: 30px 0;
    flex-direction: column;
    align-items: center;
  }

  .imageContainer {
    max-width: 350px;
    margin: 10px;
    overflow: hidden;
    img {
      border-radius: 8px;
      width: 100%;
      object-fit: contain;
    }
  }
  .contentContainer {
    width: 50%;
    margin-left: 30px;
    @include media('<smTablet') {
      width: 90%;
      margin: 0 10px;
    }
    h1 {
      font-size: 46px;
      font-weight: 700;
      margin-bottom: 12px;

      @include media('<smTablet') {
        text-align: center;
        font-size: 36px;
      }
      @include media('<mobile') {
        font-size: 26px;
      }
    }
    label {
      display: inline-block;
      margin-top: 12px;
    }
  }
}

.tabs {
  margin-top: 20px;
  margin-bottom: 10px;
}

@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';

.wrapper {
  padding-top: 50px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;

    @include media('<900px') {
      width: 90%;
    }

    h1 {
      font-size: 46px;
      font-weight: 700;

      background-image: linear-gradient(145deg, #f81f01, #ee076e);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include media('<smTablet') {
        font-size: 36px;
        text-align: center;
      }
    }

    .description {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 28px;
      color: $textColor;
    }

    .form {
      max-width: 600px;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
    }
  }

  .imageContainer {
    width: 40%;
    max-width: 400px;
    min-width: 280px;
    margin-left: 46px;
    margin-bottom: 65px;
    @include media('<900px') {
      display: none;
    }
    img {
      width: 100%;
      border-radius: 8px;
      object-fit: contain;
    }
  }

  .inputsContainer {
    display: flex;
    width: 100%;
    @include media('<=mobile') {
      flex-direction: column;
    }
    :first-child {
      @include media('>mobile') {
        margin-right: 16px;
      }
    }
  }

  .input,
  .textarea,
  .reCaptchaContainer {
    margin-bottom: 15px;
  }
}

.button {
  margin: auto;
  align-self: center;
  @include media('<mobile') {
    width: 100%;
  }
}

.reCaptchaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  margin-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @include media('<mobile') {
    height: auto;
  }
  .testimonialContainer {
    position: relative;
    padding-top: 80px;

    width: 100%;
    max-width: 1200px;
    min-height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include media('<=lgTablet') {
      padding-top: 60px;
      min-height: 200px;
    }

    &::before {
      position: absolute;
      left: 20%;
      top: 15px;
      content: '';
      width: 150px;
      height: 150px;
      background-image: url('../../assets/images/first-quotes.png');
      opacity: 0.05;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @include media('<=lgTablet') {
        top: 0;
        left: 10%;
      }
      @include media('<smTablet') {
        width: 90px;
        height: 90px;
      }
    }
    &::after {
      position: absolute;
      right: 20%;
      bottom: -70px;
      content: '';
      width: 150px;
      height: 150px;
      background-image: url('../../assets/images/end-quotes.png');
      opacity: 0.05;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @include media('<=lgTablet') {
        right: 10%;
      }
      @include media('<smTablet') {
        width: 90px;
        height: 90px;
      }
    }
    h2 {
      color: #fff;
      font-weight: 500;
      padding: 0 9%;
      position: relative;
      z-index: 2;
      font-size: 32px;
      line-height: 53px;
      text-align: center;

      @include media('<lgTablet') {
        font-size: 24px;
        line-height: 46px;
      }

      @include media('<smTablet') {
        font-size: 24px;
        line-height: 46px;
        height: auto;
      }

      @include media('<mobile') {
        font-size: 20px;
        line-height: 32px;
        height: auto;
      }
    }
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    line-height: 50px;
    color: $textColor;
    font-weight: 300;
    span {
      color: white;
    }
  }

  .clientsContainer {
    margin: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

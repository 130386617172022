@import '../../styles/variables.scss';

.navLink {
  position: relative;
  padding: 20px 0px;
  padding-top: 10px;
  height: 50px;
  padding-bottom: 10px;
  font-size: 1rem;
  font-weight: 500;

  color: $textColor;

  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    width: 0;
    height: 3px;
    background: $primaryColor;
    bottom: 0;
    transition: width 0.3s;
  }

  &:hover {
    color: $primaryColor;
  }

  &.active {
    color: $primaryColor;
    &::after {
      width: 100%;
    }
  }
}

@import '../../styles/variables.scss';

.slider {
  margin-top: 20px;
  padding-top: 5px;
  .card {
    height: auto;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    overflow: hidden;
    background-color: #191919;

    transition: all 0.4s;

    &:hover {
      transform: scale(1.02) translateY(-4px);
    }

    .imageContainer {
      width: 100%;
      max-height: 300px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .body {
      height: 130px;
      padding-top: 12px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

.videoContainer {
  position: fixed;
  width: 80%;
}

:global {
  .slick-slide > div {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
  }
  .slick-dots {
    bottom: -60px;

    li {
      width: 30px;
      margin: 0;
      opacity: 0.35;
      position: relative;

      button {
        &::before {
          display: none;
        }
        &::after {
          width: 100%;
          display: block;
          content: '';
          position: absolute;
          left: 0;
          width: 6px;
          height: 6px;
          transition: all 0.4s;
          border-radius: 50%;
          margin-left: 11px;
          background-color: transparent;
          background: #ffffff;
          box-shadow: inset 0 0 0 5px #ffffff;
        }
      }
      &.slick-active {
        opacity: 1;
        button {
          &::after {
            -webkit-transform: scale(2);
            transform: scale(2);
            box-shadow: inset 0 0 0 1px $primaryColorHover;
            background-color: transparent;
          }
        }
      }
    }
  }
}
